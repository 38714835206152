<script lang="ts" setup>
import type { RouteLocationRaw } from "vue-router";
import { mdiArrowRight } from "@mdi/js";
const { xs } = useDisplay();

const props = withDefaults(
  defineProps<{
    image?: string;
    color: string;
    flip?: boolean;
    to?: RouteLocationRaw;
    href?: string;
    externalLink?: boolean;
    title?: string;
  }>(),
  {
    flip: false,
    to: "",
    href: "",
  },
);

const isSvg = computed(() => props.image?.endsWith(".svg"));
</script>

<template>
  <v-card
    class="mb-8 mb-lg-16 card-media"
    :class="{ 'ml-auto': flip, 'mr-auto': !flip }"
    :to="to"
    :href="href"
  >
    <div
      class="d-flex align-stretch ga-sm-6 ga-md-12 ga-lg-16"
      :class="{ 'flex-row-reverse': flip }"
    >
      <v-sheet
        :color="color"
        class="pa-2 pa-sm-8 pa-md-12 card-media__img-wrapper d-sm-flex justify-center flex-column d-none"
        rounded="circle"
        style="transform: scale(140%); --v-border-opacity: 1"
        :class="{ 'pl-md-8': flip, 'pr-md-8': !flip }"
      >
        <slot name="icon" v-if="image || $slots.icon">
          <img
            v-if="isSvg"
            :src="image"
            alt=""
            loading="lazy"
            height="auto"
            style="max-width: 250px; object-fit: contain"
            :style="{
              'padding-left': !flip ? '0.5rem' : '',
              'padding-right': flip ? '0.5rem' : '',
            }"
          />
          <nuxt-img
            v-else
            :src="image"
            alt=""
            height="250px"
            loading="lazy"
            style="height: auto"
            :style="{
              'padding-left': !flip ? '0.5rem' : '',
              'padding-right': flip ? '0.5rem' : '',
            }"
          />
        </slot>
      </v-sheet>

      <div
        class="pa-sm-8 d-flex flex-column justify-center w-100 card-media__text"
        :class="{ 'pl-sm-6 pl-lg-10': flip, 'pr-sm-6 pr-lg-10': !flip }"
      >
        <div class="d-flex">
          <div class="w-100">
            <div
              class="card__header--mobile-grid"
              :class="{ 'reverse--mobile-img': flip }"
            >
              <!-- title -->
              <div class="title__area" v-if="xs">
                <div
                  class="text-h3 card__title mx-sm-2 pt-6"
                  :class="{ 'pr-6 pl-3': flip, 'pl-6 pr-3': !flip }"
                >
                  <MDCSlot name="title" v-if="$slots.title" />
                </div>
              </div>
              <!-- image -->
              <div
                class="image__area py-0 px-0 d-sm-none"
                :class="{ 'mr-auto': flip, 'ml-auto': !flip }"
              >
                <v-sheet
                  width="100px"
                  height="75px"
                  :color="color"
                  class="d-flex justify-center hw-card__image--mobile align-center"
                  :style="{
                    'clip-path': flip
                      ? 'ellipse(100% 100% at 0% 0%)'
                      : 'ellipse(100% 100% at 100% 0%)',
                  }"
                  rounded="0"
                >
                  <slot name="icon">
                    <v-responsive
                      aspect-ratio="1"
                      class="d-flex justify-center"
                      content-class="d-flex justify-center align-center image--min-size pt-1 px-md-16"
                      :class="{ 'pr-5 pb-3': flip, 'pl-5 pb-3': !flip }"
                    >
                      <hw-image
                        v-if="image"
                        :src="image"
                        object-fit="contain"
                        style="max-width: 70% !important; height: 100%"
                      />
                    </v-responsive>
                  </slot>
                </v-sheet>
              </div>
            </div>

            <div class="px-6 px-sm-0 pb-3 pt-0 pa-sm-0">
              <div v-if="!xs">
                <MDCSlot name="title" />
              </div>

              <MDCSlot name="default" />
            </div>
          </div>
          <div class="d-flex align-end">
            <v-icon
              v-if="to || href"
              class="ml-auto mr-4"
              :class="{
                'icon--external-link': externalLink,
              }"
            >
              {{ mdiArrowRight }}
            </v-icon>
          </div>
        </div>

        <div class="card__underline pb-2 px-6 px-sm-0" />
      </div>
    </div>
  </v-card>
</template>

<style lang="scss">
@use "assets/scss/settings";

@media #{map-get(settings.$display-breakpoints, 'xs')} {
  .card__title > * {
    word-break: break-word;
  }
}

a .card__underline::before {
  background: transparent
    linear-gradient(90deg, #f26d85 0%, #fa8f3c 33%, #82d9d0 67%, #30a9d9 100%)
    0% 0% no-repeat padding-box !important;
}

.card-media {
  @media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
    //width: 80%;
  }

  &__text {
    @media #{map-get(settings.$display-breakpoints, 'sm-and-up')} {
      max-width: 90%;
    }
  }
}

.card-media__img-wrapper {
  width: 70px;

  @media #{map-get(settings.$display-breakpoints, 'sm')} {
    width: 150px;
  }

  /**
  for images placed in container with container query
   */
  @container (min-width: 400px) {
    width: 150px !important;
    padding: 40px !important;
  }

  @container (min-width: 700px) {
    width: 180px !important;
  }

  @media #{map-get(settings.$display-breakpoints, 'md-and-up')} {
    width: 250px;
  }

  img {
    min-height: 100px;
    object-fit: contain !important;
  }
}
</style>
